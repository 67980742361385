<template>
  <div class="main">
    <!-- 面包屑导航 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品生产信息</el-breadcrumb-item>
      <el-breadcrumb-item>上传产品生产信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图区 -->
    
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="审定数据库" name="first"> -->
      <shending></shending>
      <el-divider></el-divider>
      <shendingadmin />
      <!-- </el-tab-pane> -->
      <!-- <el-tab-pane label="认定数据库" name="second" ><rending :key="second"></rending></el-tab-pane>
    <el-tab-pane label="登记数据库" name="three" ><dengji :key="three"></dengji></el-tab-pane> -->

      <!-- </el-tabs> -->
 
  </div>
</template>

<script>
import shending from "./shending.vue";
import shendingadmin from "./shendingadmin.vue"
// import rending from "./rending.vue";
// import dengji from "./dengji.vue";
export default {
  components: {
    shending: shending,
    shendingadmin
    // rending: rending,
    // dengji: dengji,
  },
  data() {
    return {
      activeName: "first",
      first: "",
      second: "",
      three: "",
      rules: {
        chandi: [{ requir: true, message: "请选择产地名称", trigger: "blur" }],
        cpmc: [{ requir: true, message: "请选择产品名称", trigger: "blur" }],
        scz: [{ requir: true, message: "请输入生产负责人", trigger: "blur" }],
        rq: [{ requir: true, message: "请选择日期", trigger: "blur" }],
      },
      tokenStr: "",
      queryInfo: {
        token: "",
        query: "",
        page: 1,
        pageSize: 8,
      },
      total: 0,
      // 列表渲染数据
      tableData: [],
      addDialogVisible: false,
      addFormInfo: {
        token: "",
        name: "",
        area: "",
        year: "",
        class: "",
        number: "",
        company: "",
        file_number: "",
        content: "",
      },

      selectchandi: [],
      selectProduct: [],
    };
  },
  created() {
    this.tokenStr = window.sessionStorage.getItem("token");
    this.getDataList();
  },
  methods: {
    getInfo(row) {
      console.log(row);
      let datarow = JSON.parse(JSON.stringify(row));
      this.addDialogVisible = true;
      this.addFormInfo = datarow;
    },
    async getDataList() {
      this.queryInfo.token = this.tokenStr;
      const { data: res } = await this.$http.post("pinzhong", this.queryInfo);
      this.tableData = res.data.row;
      this.total = res.data.total;
      console.log(res.data);
      //  新增产地，下拉框数据
      // const {data:selectRes} =await this.$http.post('SelectPlace',{token:this.tokenStr})
      // this.selectchandi = selectRes.data;
      //  //  产品名称，下拉框数据
      // const {data: selectProductRes} =await this.$http.post('SelectProduction',{token:this.tokenStr})
      // this. selectProduct =  selectProductRes.data;
      // console.log(selectProductRes)
    },
    // 分页功能--监听当前页码值 改变事件
    handleCurrentChange(newPage) {
      // console.log("当前页是" + newPage);
      this.queryInfo.page = newPage;
      this.getDataList();
    },
    closedialog() {
      for (var name in this.addFormInfo) {
        this.addFormInfo[name] = "";
      }
      // this.getDataList();
    },
    addFormSubmit() {
      this.addFormInfo.token = this.tokenStr;
      this.$http.post("addPinZhong", this.addFormInfo).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.$message.success("添加信息成功");
          this.getDataList();
        }
      });
      this.addDialogVisible = false;
      this.getDataList();
    },
    //   async delInfo(id){
    //     const confirmRes =await this.$confirm('此操作将永久删除该条信息, 是否继续?', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).catch(err=>err)
    //     if(confirmRes !='confirm'){
    //       return this.$message.info('取消删除')
    //     }
    //     const res = await this.$http.post('DelProductionInfo',{
    //         token:this.tokenStr,bh:id
    //       })
    //     if(res.status !== 200){
    //       return this.$message.error('删除失败')
    //     }
    //     this.$message.success('删除成功')
    //     this.getDataList();
    //     console.log(res)
    // }
  },
};
</script>

<style scoped lang="less">
.el-breadcrumb {
  height: 30px;
}
.el-pagination {
  margin-top: 10px;
}

// .box-card{
//    background-color: #000d4a;
// }
</style>

